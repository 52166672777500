<template>
    <page title="我的考勤" :hasBack="true" textAlign="left">
        <van-calendar
            :show-confirm="false"
            :show-title="false"
            :poppable="false"
            @confirm="dateConfirm"
            :min-date="minDate"
            :style="{ height: '345px' }"
        />
        <common-block class="attendanceList" title="考勤明细">
            <template v-if="!stepListHasLoad || stepList.length">
                <common-step :stepList="stepList" />
            </template>
            <div class="empty-wrapper" v-else>
                <empty />
            </div>
        </common-block>
    </page>
</template>
<script>
import Page from '@/components/page/Page';
import CommonBlock from '@/components/commonBlock/CommonBlock.vue';
import CommonStep from '@/components/commonStep/CommonStep';
import Empty from '@/components/empty/Empty';
import { Calendar } from 'vant';
import { ref } from 'vue';
import {getStorage} from "@/utils/storage";
import commonRequest from '@/services';
import dayjs from 'dayjs';
import {DATE_FORMATE} from "@/constants";

export default {
    name: 'schoolNotice',
    components: {
        [Page.name]: Page,
        [Calendar.name]: Calendar,
        [CommonStep.name]: CommonStep,
        [CommonBlock.name]: CommonBlock,
        [Empty.name]: Empty,
    },
    setup() {
        const minDate = ref(new Date('1970-01-01'));
        const nowDate = ref(new Date());
        const dateConfirm = (value) => {
            nowDate.value = new Date(value);
            fetchLog();
        };
        const stepList = ref([
            // { title: '进入校门', content: '2021-10-01 07:18:15', id: 1 },
            // { title: '进入校门', content: '2021-10-01 07:18:15', id: 2 },
            // { title: '进入校门', content: '2021-10-01 07:18:15', id: 3 },
        ]);
        const stepListHasLoad = ref(false);
        const chooseStudentId = getStorage('chooseStudentId');
        const fetchLog = () => {
            commonRequest({ action: 11016, studentUserid: chooseStudentId, dateTime: dayjs(nowDate.value).format(DATE_FORMATE)}).then((res) => {
                stepListHasLoad.value = true
                if (res.data.behaviorRecordListResponses && res.data.behaviorRecordListResponses.length) {
                    stepList.value = res.data.behaviorRecordListResponses[0].behaviorRecordLogResponseList.map((item, index) => ({
                        title: item.content,
                        content: item.createTime,
                        id: index
                    }))
                }
            })
        }
        fetchLog();
        return {
            dateConfirm,
            minDate,
            stepList,
            stepListHasLoad,
            maxDate: new Date(2021, 10, 14),
        };
    },
};
</script>
<style lang="less" scoped>
/deep/ .van-calendar__day {
    height: 45px;
}
/deep/ .van-calendar__selected-day {
    width: 34px;
    height: 34px;
    background: #07c160;
    box-shadow: 0px 2px 10px rgba(7, 193, 96, 0.5);
    border-radius: 50%;
}
/deep/ .van-calendar {
    border-radius: 5px;
}
    .empty-wrapper {
        height: 240px;
    }
</style>
